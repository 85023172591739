<template>
  <div class="settings-container">
    <setting-nav></setting-nav>
    <div class="content-container">
      <!-- Mobil Uygulama Bildirim Ayarları-->
      <section class="section">
        <div class="section-header-body" style="margin-top: 0px;">
          <h3 class="section-header">Mobil Uygulama <span>Bildirim Ayarları</span></h3>
          <div class="edit-area" @click="editAreaClick(1)" v-if="editableSectionId !== 1">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[0]">
            <loader/>
          </div>
          <!--not editable area-->
          <div class="notif-body" v-if="editableSectionId !== 1">
            <p class="notification-text">Mobil Uygulama Bildirim Ayarları</p>
            <p class="notification-text2">{{ settingsNotificationsMobileActivitySelect.find(({ value }) => value === user.info.permissions.notification_mobile_setting) ? settingsNotificationsMobileActivitySelect.find(({ value }) => value === user.info.permissions.notification_mobile_setting).title : null}}</p>
          </div>

          <!--editable area-->
          <div v-else>
            <custom-select label="Mobil Uygulama Bildirim Ayarları"
                           id="settingsNotificationsActivitySelect"
                           :options="settingsNotificationsMobileActivitySelect"
                           :customOption="{name: 'title', value:'value'}"
                           :valid="validationNotifInfo.notificationMobilSetting !== notifInfo.notificationMobilSetting ? true : null"
                           v-model="notifInfo.notificationMobilSetting"
                           style="width: 393px;"/>
          </div>

          <div class="separator" style="margin-top: 30px;" v-if="editableSectionId === 1"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 1">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(1)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(1)" @click="saveButton(1)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!-- E-Posta Bildirim Ayarları-->
      <section class="section">
        <div class="section-header-body">
          <h3 class="section-header">E-Posta <span>Bildirim Ayarları</span></h3>
          <div class="edit-area" @click="editAreaClick(2)" v-if="editableSectionId !== 2">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[1]">
            <loader/>
          </div>
          <!--not editable area-->
          <div class="notif-body" v-if="editableSectionId !== 2">
            <p class="notification-text">Hangi Durumlarda E-Posta Gönderilsin</p>
            <p class="notification-text2">{{ settingsNotificationsActivitySelect.find(({ value }) => value === user.info.permissions.notification_email_setting) ? settingsNotificationsActivitySelect.find(({ value }) => value === user.info.permissions.notification_email_setting).title : null}}</p>
          </div>
          <div class="separator" style="margin-top: 18px; margin-bottom: 18px;" v-if="editableSectionId !== 2"></div>
          <div class="notif-body" v-if="editableSectionId !== 2">
            <p class="notification-text">Kampanya ve Haftalık Bülten E-Postaları</p>
            <p class="notification-text2">{{ settingsNotificationEmailCampaignSelect.find(({ value }) => value === user.info.permissions.email_campaign_setting) ? settingsNotificationEmailCampaignSelect.find(({ value }) => value === user.info.permissions.email_campaign_setting).title : null}}</p>
          </div>
          <!--editable area-->
          <div v-else>
            <custom-select label="Hangi Durumlarda E-Posta Gönderilsin"
                           id="settingsNotificationsActivitySelect"
                           :options="settingsNotificationsActivitySelect"
                           :customOption="{name: 'title', value:'value'}"
                           :valid="validationNotifInfo.notificationEmailSetting !== notifInfo.notificationEmailSetting ? true : null"
                           v-model="notifInfo.notificationEmailSetting"
                           style="width: 393px;"/>

            <custom-select label="Kampanya ve Haftalık Bülten E-Postaları"
                           id="settingsNotificationEmailCampaignSelect"
                           :options="settingsNotificationEmailCampaignSelect"
                           :customOption="{name: 'title', value:'value'}"
                           :valid="validationNotifInfo.emailCampaignSetting !== notifInfo.emailCampaignSetting ? true : null"
                           v-model="notifInfo.emailCampaignSetting"
                           style="width: 393px; margin-top: 42px;"/>
          </div>

          <div class="separator" style="margin-top: 30px;" v-if="editableSectionId === 2"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 2">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(2)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(2)" @click="saveButton(2)">Kaydet</custom-button>
          </div>
        </div>
      </section>

      <!-- Web Sitesi Bildirim Ayarları-->
      <section class="section">
        <div class="section-header-body">
          <h3 class="section-header">Web Sitesi <span>Bildirim Ayarları</span></h3>
          <div class="edit-area" @click="editAreaClick(3)" v-if="editableSectionId !== 3">
            <img src="https://gcdn.bionluk.com/site/icon/aws/icon_edit_kalem.svg" alt="kalem" width="14" height="14">
            <p class="edit-text">Düzenle</p>
          </div>
        </div>
        <div class="section-body">

          <!--loader-->
          <div class="partial-loader" v-if="requestWaiter[2]">
            <loader/>
          </div>
          <!--not editable area-->
          <div class="notif-body" v-if="editableSectionId !== 3">
            <p class="notification-text">Mesaj ve Bildirimlerde Uyarı Sesi</p>
            <p class="notification-text2">{{user.info.permissions.play_sound ? 'Açık' : 'Kapalı'}}</p>
          </div>

          <!--editable area-->
          <div v-else>
            <custom-select label="Mesaj ve Bildirimlerde Uyarı Sesi"
                           id="playSoundOptions"
                           :options="playSoundOptions"
                           :customOption="{name: 'title', value:'value'}"
                           :valid="validationNotifInfo.playSound !== notifInfo.playSound ? true : null"
                           v-model="notifInfo.playSound"
                           style="width: 393px;"/>
          </div>

          <div class="separator" style="margin-top: 30px;" v-if="editableSectionId === 3"></div>
          <div class="save-cancel-body" v-if="editableSectionId === 3">
            <custom-button buttonType="border" style="width: 100px;" :disabled="disableCancelButton(3)" @click="cancelButton()">İptal</custom-button>
            <custom-button buttonType="green" style="width: 170px; margin-left: 30px;" :disabled="disableSaveButton(3)" @click="saveButton(3)">Kaydet</custom-button>
          </div>
        </div>
      </section>
    </div>
    <div class="grey-background"></div>
  </div>
</template>

<script>
  import SettingNav from "../navComponent";
  import CustomSelect from "../../../components/CustomSelect";
  import CustomButton from "../../../components/CustomButton";
  import helpBox from "../../../components/HelpBox";

  export default {
    name: "src-pages-body-settings-notification-v2",
    components: {
      SettingNav,
      CustomSelect,
      CustomButton,
      helpBox
    },
    data() {
      return {
        editableSectionId: null,
        requestWaiter: [
          false,
          false,
          false
        ],

        settingsNotificationsActivitySelect: [
          {
            title: "Tüm Aktivitelerde Gönder",
            value: 2
          },
          {
            title: "Sadece Önemli Aktivitelerde Gönder",
            value: 1
          },

        ],
        settingsNotificationsMobileActivitySelect: [
          {
            title: "Tüm Aktivitelerde Gönder",
            value: 2
          },
          {
            title: "Sadece Önemli Aktivitelerde Gönder",
            value: 1
          },
          {
            title: "Hiçbir Zaman Gönderme",
            value: 0
          },
        ],
        settingsNotificationEmailCampaignSelect: [
          {
            title: "Tüm Kampanyalarda Gönder",
            value: 2
          },
          {
            title: "Sadece Blog Yazılarında Gönder",
            value: 1
          },
          {
            title: "Hiçbir Zaman Gönderme",
            value: 0
          },
        ],
        playSoundOptions: [
          {
            title: "Açık",
            value: 2
          },
          {
            title: "Kapalı",
            value: 1
          },
        ],

        notifInfo: {},
        validationNotifInfo: {}

      }
    },

    methods: {
      setInitialValues() {
        const notifInfo = {
          notificationMobilSetting: this.user.info.permissions.notification_mobile_setting,
          notificationEmailSetting: this.user.info.permissions.notification_email_setting,
          notificationEmailInterval: this.user.info.permissions.notification_email_interval,
          emailCampaignSetting: this.user.info.permissions.email_campaign_setting,
          playSound: this.user.info.permissions.play_sound ? 2 : 1
        };

        this.notifInfo = {...notifInfo};
        this.validationNotifInfo = {...notifInfo};
      },

      disableSaveButton(sectionId) {
        if (this.requestWaiter[sectionId - 1]) return true;
        switch (sectionId) {
          case 1:
            return this.notifInfo.notificationMobilSetting === this.user.info.permissions.notification_mobile_setting;
          case 2:
            return this.notifInfo.notificationEmailSetting === this.user.info.permissions.notification_email_setting && this.notifInfo.emailCampaignSetting === this.user.info.permissions.email_campaign_setting;
          case 3:
            return this.notifInfo.playSound === (this.user.info.permissions.play_sound ? 2 : 1);

        }
      },

      disableCancelButton(sectionId) {
        if (this.requestWaiter[sectionId - 1]) return true;
        return false;
      },

      editAreaClick(section = null) {
        this.editableSectionId = section;
      },

      handleRequestWaiter(sectionId, boolean = false) {
        const requestWaiter = [...this.requestWaiter];
        requestWaiter[sectionId - 1] = boolean;
        this.requestWaiter = [...requestWaiter];
      },

      cancelButton() {
        this.setInitialValues();
        this.editableSectionId = null;
      },

      saveButton(sectionId) {
        this.handleRequestWaiter(sectionId, true);
        if (sectionId === 1) this.notificationMobilSetting(sectionId);
        if (sectionId === 2) this.updateSection2(sectionId);
        if (sectionId === 3) this.changeSoundSetting(sectionId);
      },

      notificationMobilSetting(sectionId) {
        this.api.user.editNotification("notification_mobile_setting", this.notifInfo.notificationMobilSetting)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.$store.commit(this.types.SET_PERMISSIONS_CHANGES, {key: "notification_mobile_setting", value: this.notifInfo.notificationMobilSetting});
              this.$toasted.global.infoToast({description: result.message});
              this.cancelButton();
            } else {
              this.setInitialValues();
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(sectionId);
          })
          .catch(err => {
            this.setInitialValues();
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });

      },

      updateSection2(sectionId) {
        let notificationEmailSetting = this.api.user.editNotification("notification_email_setting", this.notifInfo.notificationEmailSetting);
        let emailCampaignSetting = this.api.user.editNotification("email_campaign_setting", this.notifInfo.emailCampaignSetting);
        Promise.all([notificationEmailSetting, emailCampaignSetting])
          .then(res => {

            if (res[0].data.success) {
              this.$store.commit(this.types.SET_PERMISSIONS_CHANGES, {key: "notification_email_setting", value: this.notifInfo.notificationEmailSetting});
            }

            if (res[1].data.success) {
              this.$store.commit(this.types.SET_PERMISSIONS_CHANGES, {key: "email_campaign_setting", value: this.notifInfo.emailCampaignSetting});
            }
            this.$toasted.global.infoToast({description: 'Başarılı'});
            this.handleRequestWaiter(sectionId);
            this.cancelButton();
          })
          .catch(err => {
            this.handleRequestWaiter(sectionId);
            this.setInitialValues();
            this.$toasted.global.errorToast({description: 'Hata Oluştu'});
          });
      },

      changeSoundSetting(sectionId) {
        this.api.user.editPlaySound(this.notifInfo.playSound === 2)
          .then(({data}) => {
            let result = data;
            if (result.success) {
              this.user.info.permissions.play_sound = this.notifInfo.playSound === 2;
              this.$toasted.global.infoToast({description: result.message});
              this.cancelButton();
            } else {
              this.setInitialValues();
              this.$toasted.global.errorToast({description: result.message})
            }
            this.handleRequestWaiter(sectionId);
          })
          .catch(err => {
            this.setInitialValues();
            this.handleRequestWaiter(sectionId);
            this.$toasted.global.errorToast({description: this.Constants.TEXTS.SERVER_ERROR_MESSAGE});
          });
      }

    },

    created() {
      this.setInitialValues();
    }

  }

</script>

<style scoped lang="scss">

  .settings-container {
    display: flex;
    min-height: calc(100vh - 111px);
    width: 1140px !important;
    margin-left: auto;
    margin-right: auto;
    background-color: #fff;
  }

  .content-container {
    box-sizing: border-box;
    padding-top: 40px;
    padding-left: 30px;
    padding-bottom: 340px;
    z-index: 1;
    background-color: #f4f5f7;
    width: 100%;
    min-height: calc(100vh - 111px);
  }

  .grey-background {
    z-index: -1;
    position: fixed;
    top: 0;
    right: 0;
    width: 50vw;
    min-height: calc(100vh);
    background-color: #f4f5f7;
  }

  .section {
    display: flex;
    flex-direction: column;
    position: relative;
  }

  .section-header-body {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 20px;
    margin-top: 60px;

    .edit-area {
      display: flex;
      flex-direction: row;
      align-items: center;
      cursor: pointer;

      .edit-text {
        font-size: 14px;
        font-weight: 400;
        color: #8b95a1;
        margin-left: 5px;
      }
    }
  }

  .section-header {
    font-size: 24px;
    font-weight: 400;
    color: #4b4f52;

    span {
      font-weight: 600;
    }
  }

  .section-body {
    position: relative;
    box-sizing: border-box;
    border: 1px solid #eaedf2;
    background-color: #fff;
    min-width: 100%;
    padding-right: 40px;
    padding-left: 40px;
    padding-top: 34px;
    padding-bottom: 34px;
  }

  .separator {
    border-bottom: 1px solid #eaedf2;
    width: 100%;
  }

  .save-cancel-body {
    margin-top: 30px;
    display: flex;
    flex-direction: row;
    justify-content: flex-end;
  }

  .notification-text {
    font-size: 16px;
    font-weight: 400;
    color: #8b95a1;
    width: 50%;
  }

  .notification-text2 {
    font-size: 16px;
    font-weight: 400;
    color: #2d3640;
  }

  .notif-body {
    display: flex;
    flex-direction: row;
    align-items: center;
  }

</style>
